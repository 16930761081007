@import './fonts.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiIconButton-colorInherit .MuiIconButton-label {
  color: #6c757d;
}

.map-button {
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.rbc-agenda-content {
  height: 270px;
  overflow-y: scroll;
}

.right-panel {
  margin-top: 64px;
  overflow-y: auto;
}

.agenda-page .rbc-month-view {
  z-index: 0;
}

.agenda-page .rbc-time-view {
  z-index: 0;
}

.agenda-page .rbc-agenda-view {
  z-index: 0;
}

.register-link {
  padding-top: 15px;
  font-size: 13px;
  text-decoration: none;
  color: grey;
}

.register-link:hover {
  text-decoration: underline;
}

.test {
  color: pink;
}

.MuiTable-root td , th {
  white-space: nowrap;
}

.category-drag-line {
  border-color: grey !important;
}

.PrivateHiddenCss-xsDown-3 {
  display: block !important;
}

main {
  max-width: calc(100% - 59px) !important;
  overflow-y: auto;
}

.header-actions-buttons {
  max-width: 100% !important;
}

.mobile-dashboard {
}

@media only screen and (max-width: 1080px) {
  main {
    max-width: 100% !important;
    overflow-y: auto;
  }

  .header-actions-buttons {
    max-width: 100% !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: flex-start;
    padding: 5px;
    height: auto !important;
  }

  .mobile-dashboard {
    flex-direction: column !important;
    width: 100% !important;
    height: auto;
  }

  .MuiPaper-rounded {
    max-width: 100% !important;
    width: 100% !important;
  }

  .MuiMenu-paper {
    min-height: 68vh !important;
    left: 0px !important;
  }
}

.MuiPaper-rounded {
  max-width: 100% !important;
}

.pac-container {
  z-index: 9999 !important;
}
.rbc-timeslot-group {
  min-height: 150px !important;
}